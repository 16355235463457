import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, reaction } from 'mobx'
import NotificationArea from 'component/NotificationArea'
import Login from 'screen/Login'
import AppHeader from './AppHeader'
import { withRouter } from 'react-router-dom'
import Router from './Router'
import StartupError from './StartupError'
import RuntimeError from './RuntimeError'
import { AppContainer, Body } from 're-cy-cle'
import Raven from 'raven-js'
import { theme } from 'styles'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { pdfjs } from 'react-pdf'
import moment from 'moment'
import { GlobalValue } from 'store/GlobalValue'
import NoPermissions from './NoPermissions'
import UnauthenticatedRouter from 'container/UnauthenticatedRouter';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const StyledBody = styled(Body)`
  h1 {
    color: ${theme.primaryColor} !important;
    font-size: 1.5rem !important;
  }
  overflow: hidden;
`

const pathsThatSkipAuthentication = [
  /^\/login\/forgot$/,
  /^\/user\/\d+\/activate\/[^/]+$/,
  /^\/user\/\d+\/reset-password\/[^/]+$/,
  /^\/register/,
  /^\/operator\/login$/,
  /^\/production\/login$/,
]

@withRouter
@observer
export default class App extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    location: PropTypes.object,
  }

  static childContextTypes = {
    viewStore: PropTypes.object,
  }

  constructor(...args) {
    super(...args)
    this.setOperatorTimeout = this.setOperatorTimeout.bind(this)
    this.checkOperatorTimeout = this.checkOperatorTimeout.bind(this)
  }

  getChildContext() {
    return {
      viewStore: this.props.store,
    }
  }

  componentDidCatch(err) {
    this.hasCrashed = true
    if (process.env.CY_FRONTEND_SENTRY_DSN) {
      Raven.captureException(err)
      Raven.showReportDialog()
    }
  }

  @observable hasCrashed = false

  operatorTimeout = null

  __operatorTimeoutDuration = null

  async getOperatorTimeoutDuration() {
    if (
      this.__operatorTimeoutDuration === null ||
      this.__operatorTimeoutDuration.fetchedAt.isBefore(moment().subtract(15, 'm'))
    ) {
      const value = new GlobalValue({ key: 'operator_login_timeout' })
      await value.fetch()
      this.__operatorTimeoutDuration = {
        value: value.value,
        fetchedAt: moment(),
      }
    }
    return this.__operatorTimeoutDuration.value
  }

  async setOperatorTimeout() {
    const { store } = this.props
    const operatorTimeoutDuration = store.isOperator ? await this.getOperatorTimeoutDuration() : null
    if (operatorTimeoutDuration !== null) {
      this.operatorTimeout = moment().add(operatorTimeoutDuration, 's')
    } else {
      this.operatorTimeout = null
    }
  }

  async checkOperatorTimeout() {
    if (this.operatorTimeout && this.operatorTimeout.isBefore(moment())) {
      this.operatorTimeout = null
      const { store, history } = this.props
      await store.performLogout()
      history.push('/operator/login')
    }
  }

  componentDidMount() {
    this.operatorReaction = reaction(() => this.props.store.isOperator, this.setOperatorTimeout, { fireImmediately: true })
    window.addEventListener('click', this.setOperatorTimeout)
    this.checkOperatorTimeoutInterval = setInterval(this.checkOperatorTimeout, 1000)
  }

  componentWillUnmount() {
    this.operatorReaction()
    window.removeEventListener('click', this.setOperatorTimeout)
    clearInterval(this.checkOperatorTimeoutInterval)
  }

  render() {
    const { store, location, history } = this.props

    if (this.hasCrashed) {
      return <RuntimeError />
    }

    let content = null
    if (
      store.isAuthenticated ||
      store.isWorkStation ||
      store.isOperator ||
      pathsThatSkipAuthentication.some((regex) => regex.test(location.pathname))
    ) {
      content = <Router store={store} />
    } else if (store.bootstrapCode === 200) {
      content = <UnauthenticatedRouter store={store} />;
    } else if (store.bootstrapCode !== null) {
      // The not null check is important, since we don't want to flash a startup error while the XHR request is running.
      return <StartupError code={store.bootstrapCode} />
    }

    //T39393 if user does not belong to any groups, show that the user does not have permissions set
    if(window.viewStore.currentUser.groups.models.length == 0 && !window.viewStore.currentUser.isSuperuser  && store.isAuthenticated){
      return <NoPermissions viewStore={store} history={history} />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{store.tabTitlePrefix}</title>
        </Helmet>
        <AppContainer data-theme={JSON.stringify(theme)}>
          {(store.isAuthenticated || store.isWorkStation || store.isOperator) && (
            <AppHeader store={store} location={location} history={history} />
          )}
          <StyledBody>{content}</StyledBody>
          {store.currentModal ? <store.currentModal.render viewStore={store} {...store.currentModal} /> : null}
          <NotificationArea store={store} />
        </AppContainer>
      </React.Fragment>
    )
  }
}
