import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route, Switch } from 'react-router-dom';
import Load from './Loadable';
import Login from 'spider/semantic-ui/Login';

const NotFound = Load(() => import('../container/NotFound'));
const TenantForm = Load(() => import('../screen/Tenant/TenantForm'))

export default class UnauthenticatedRouter extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    render() {
        return (
            <Switch>
                <Route path="/tenant-form" render={this.route(TenantForm)} />

                <Route path="/" render={this.route(Login)}/>
                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
